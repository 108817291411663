<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New User
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              :label="$t('Name')"
              label-for="name"
            >
              <template v-slot:label>
                {{ $t('Name') }} <code>*</code>
              </template>
              <b-form-input
                id="name"
                v-model="userData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John"
              />
            </b-form-group>
          </validation-provider>

          <!-- Surname -->
          <validation-provider
            #default="validationContext"
            name="Surname"
          >
            <b-form-group
              :label="$t('Surname')"
              label-for="surname"
            >
              <b-form-input
                id="surname"
                v-model="userData.surname"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- legalId -->
          <validation-provider
            #default="validationContext"
            name="legal_id"
          >
            <b-form-group
              :label="$t('legal Id')"
              label-for="legal_id"
            >
              <b-form-input
                id="legal_id"
                v-model="userData.legal_id"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Role -->
          <validation-provider
            #default="{ errors }"
            name="role"
            rules="required"
          >
            <b-form-group
              :label="$t('Role')"
              label-for="role"
              :state="errors.length > 0 ? false:null"
            >
              <template v-slot:label>
                {{ $t('Role') }} <code>*</code>
              </template>
              <v-select
                id="role"
                v-model="userData.role"
                :options="availableRoles"
                :reduce="availableRoles => availableRoles.value"
                label="text"
                autofocus
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- address1 -->
          <validation-provider
            #default="validationContext"
            name="address1"
          >
            <b-form-group
              :label="$t('address1')"
              label-for="address1"
            >
              <b-form-input
                id="address1"
                v-model="userData.address1"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- address2 -->
          <validation-provider
            #default="validationContext"
            name="address2"
          >
            <b-form-group
              :label="$t('address2')"
              label-for="address2"
            >
              <b-form-input
                id="address2"
                v-model="userData.address2"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- city -->
          <validation-provider
            #default="validationContext"
            name="city"
          >
            <b-form-group
              :label="$t('city')"
              label-for="city"
            >
              <b-form-input
                id="city"
                v-model="userData.city"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- state -->
          <validation-provider
            #default="validationContext"
            name="state"
          >
            <b-form-group
              :label="$t('state')"
              label-for="state"
            >
              <b-form-input
                id="state"
                v-model="userData.state"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- postalCode -->
          <validation-provider
            #default="validationContext"
            name="postal_code"
          >
            <b-form-group
              :label="$t('Postal Code')"
              label-for="postal_code"
            >
              <b-form-input
                id="postal_code"
                v-model="userData.postal_code"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- telephone -->
          <validation-provider
            #default="validationContext"
            name="telephone"
          >
            <b-form-group
              :label="$t('telephone')"
              label-for="telephone"
            >
              <b-form-input
                id="telephone"
                v-model="userData.telephone"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              :label="$t('Email')"
              label-for="email"
            >
              <template v-slot:label>
                Email <code>*</code>
              </template>
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- comments -->
          <validation-provider
            #default="validationContext"
            name="comments"
          >
            <b-form-group
              :label="$t('comments')"
            >
              <b-form-input
                id="comments"
                v-model="userData.comments"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- avatar -->
          <validation-provider
            #default="validationContext"
            name="avatar"
          >
            <b-form-group
              :label="$t('Avatar')"
              label-for="avatar"
            >
              <b-form-file
                v-model="avatar"
                :placeholder="$t('Choose a file or drop it here')"
                :drop-placeholder="$t('Drop file here')"
              />

              <b-card-text class="my-1">
                {{ $t('Selected file:') }} <strong>{{ avatar ? avatar.name : '' }}</strong>
              </b-card-text>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>

import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormFile, BCardText, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onMounted, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import useUserView from "@/views/apps/user/users-view/useUserView"

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardText,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      legal_id: '',
      name: '',
      surname: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postal_code: '',
      telephone: '',
      email: '',
      comments: '',
      role: '',
    }

    const avatar = ref(null)

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetUserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const onSubmit = () => {
      const formData = new FormData()
      Object.entries(userData.value).forEach(([key, val]) => formData.append(key, val))
      formData.append('avatar', avatar.value)
      store.dispatch('model/user/addUser', formData)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUserData)

    const {
      fetchRoles, availableRoles,
    } = useUserView()

    onMounted(fetchRoles)

    return {
      userData,
      onSubmit,
      availableRoles,

      refFormObserver,
      getValidationState,
      resetForm,
      avatar,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
